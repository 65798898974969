export const namespaces = [
    'common',
    'enum',
    'field',
    'home',
    'header',
    'notifications',
    'course-list',
    'course-detail',
    'confirm-dialog',
];
